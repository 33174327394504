import React, { Fragment } from 'react';
import Resume from '../components/Resume';
// import SEO from '../components/SEO';

function ResumePage() {
  
  return (
    <Fragment>
      {/* <SEO
        title="Contact"
        pathname={path}
      /> */}
      <Resume />
    </Fragment>
  );
}

// ContactPage.propTypes = {
//   path: PropTypes.string.isRequired,
// };

export default ResumePage;

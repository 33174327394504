import React from 'react';
import './index.scss';
// const resume = require('./fran.pdf');
import resume from './zaidkhan.pdf'
const googleLinkToResume = `https://drive.google.com/file/d/1_2ZCF98rIOQ8vBk9KwHrgKyrvep9--NQ/view?usp=sharing`;
const embeddedGoogleLinkToResume = `https://drive.google.com/file/d/1_2ZCF98rIOQ8vBk9KwHrgKyrvep9--NQ/preview`;

const Resume = () => {
  
    return (
    // <object
    //     className="full-bleed below-header resume-object"
    //     data={resume}
    //     type="application/pdf"
    //   >
    //     <iframe
    //       title="resume"
    //       width={window.innerWidth}
    //       height={window.innerHeight - 45}
    //       className="resume-iframe"
    //       src={embeddedGoogleLinkToResume}
    //     >
    //       This browser does not support PDFs. Please download the PDF to view
    //       it:
    //       <a href={googleLinkToResume}>Download PDF</a>
    //     </iframe>
    //   </object>

        //  <embed src={resume} />
        // <iframe src={resume} style={{width: '100%', height: '100%'}}></iframe>
        // <h1>fucker</h1>
        <div>
          {/* <iframe src={resume} type="application/pdf" style={{ width: '100%', height: '600px'}} /> */}
          {/* <object data={resume} type="application/pdf" style={{ width: '100%', height: '600px'}}> 
            <a href={embeddedGoogleLinkToResume}>Resume</a>
          </object> */}
          {/* <a href={googleLinkToResume}></a> */}
        </div>
        )
    }

export default Resume;

